<template>
    <div class="pane-wrapper">
        <div class="table-wrapper">
            <y-table :columns="columns" :loading="loading" :service="service" :entity="entity" :sorter="sorter"
                :filters="privateFilters" :addable="false" @add="handleClickAdd">
                <template slot="action" slot-scope="action">
                    <a-button type="link" @click="handleEditClick(action.record)">编辑</a-button>
                    <a-button type="link" @click="handleDeleteClick(action.record)">删除</a-button>
                </template>
            </y-table>
        </div>
        <editor :visible.sync="visible" :target="target" @close="handleEditorClose"></editor>
    </div>
</template>

<script>
import AppointmentService from "../../../services/appointment";
import Editor from "../editor";
const defaultFilters = {
    type: "SUPPLY",
};
const defaultEntity = {
    properties: {},
};
export default {
    components: { Editor },
    props: {
        loading: {
            type: Boolean,
            default: false,
        },
        filters: {
            type: Object,
        },
        sorter: {
            type: Array,
        },
        entity: {
            type: Object,
            default: () => {
                return JSON.parse(JSON.stringify(defaultEntity));
            },
        },
    },
    data() {
        return {
            service: AppointmentService,
            visible: false,
            visibleSortModal: false,
            privateFilters: JSON.parse(JSON.stringify(defaultFilters)),

            target: {},
            columns: [
                {
                    key: 'portrait',
                    dataIndex: "user",
                    title: "微信头像",
                    customRender: (text) => {
                        return (
                            <img
                                style="cursor: pointer;"
                                src={text.accounts.length > 0 ? text.accounts[0].portrait : ""}
                                width="100"
                                alt=""
                                srcset=""
                            />
                        );
                    },
                },
                {
                    key: 'name',
                    dataIndex: "user",
                    title: "姓名",
                    customRender: (text) => {
                        return (
                            <y-tag
                                value={
                                    text.accounts.length > 0
                                        ? text.accounts[0].name || text.accounts[0].name
                                        : ""
                                }
                            ></y-tag>
                        );
                    },
                },
                {
                    dataIndex: "exhibitor.name",
                    title: "展商",
                },
                {
                    key:'phone',
                    dataIndex: "user",
                    title: "手机号",
                    customRender: (text) => {
                        return <y-tag value={
                            text.accounts.length > 0
                                ? text.accounts[0].phone ? text.accounts[0].phone.number : ""
                                : ""
                        }></y-tag>;
                    },
                },

                {
                    dataIndex: "description",
                    title: "备注",
                },
                {
                    dataIndex: "startTime",
                    title: "预约时间",
                    scopedSlots: { customRender: "dateTime" },
                },
                // {
                //     dataIndex: "endTime",
                //     title: "结束时间",
                //     scopedSlots: { customRender: "dateTime" },
                // },
            ],
        };
    },
    watch: {
        filters: {
            handler: async function (val) {
                this.privateFilters = val;
            },
            immediate: true,
            deep: true,
        },
    },
    methods: {
        handleClickAdd() {
            this.target = {};
            this.visible = true;
        },
        handleEditClick(record) {
            this.target = record;
            this.visible = true;
        },
        handleEditorClose() {
            this.target = JSON.parse(JSON.stringify(defaultEntity));
            this.privateFilters = JSON.parse(JSON.stringify(this.filters));
        },
        async handleDeleteClick(record) {
            try {
                this.loading = true;
                await this.service.deleteEntity(record);
            } catch (error) {
                this.$message.error(
                    error.response ? error.response.data.message : error.message
                );
            } finally {
                this.privateFilters = JSON.parse(JSON.stringify(this.filters));
                this.loading = false;
            }
        },
    },
};
</script>

<style>
</style>