<template>
  <div class="share-record">
    <div class="search-wrapper">
      <a-form-model :model="filters" :layout="'inline'" @submit.prevent="handleSearch">
        <a-form-model-item label="展商" prop="exhibitor">
          <y-select v-model="entity.exhibitor" placeholder="请选择展商" :whole="false" :dataSource="exhibitors"></y-select>
        </a-form-model-item>
        <a-form-model-item prop="account.name" label="姓名">
          <a-input v-model="filters.account.name" type="text" placeholder="输入搜索" allowClear @change="
            () => {
              if (filters.account.name == '') delete filters.account.name;
            }
          "></a-input>
        </a-form-model-item>

        <a-form-model-item prop="account.phone.number" label="手机号">
          <a-input v-model="filters.account.phone.number" type="text" placeholder="输入搜索" allowClear @change="
            () => {
              if (filters.account.phone.number == '') delete filters.account.phone.number;
            }
          "></a-input>
        </a-form-model-item>
        <a-form-model-item prop="description" label="备注">
          <a-input v-model="filters.description" type="text" placeholder="输入搜索" allowClear @change="
            () => {
              if (filters.description == '') delete filters.description;
            }
          "></a-input>
        </a-form-model-item>
        <a-form-model-item>
          <a-space>
            <a-button icon="search" :loading="loading" type="primary" htmlType="submit">
              搜索
            </a-button>
            <a-button icon="redo" :loading="loading" htmlType="reset" @click="handleReset">
              重置
            </a-button>
          </a-space>
        </a-form-model-item>
      </a-form-model>
    </div>
    <appointment-table :loading.sync="loading" :entity="entity" :filters="filters" :sorter="[
      { field: 'createTime', order: 'DESC' },
      { field: 'sequence', order: 'DESC' },
    ]"></appointment-table>
  </div>
</template>

<script>
import AppointmentTable from "./table";
import ExhibitorService from "../../services/exhibitor";

const defaultEntity = {
  account: { phone: {} },
};
export default {
  components: { AppointmentTable },
  data() {
    return {
      exhibitorService: ExhibitorService,
      loading: false,
      entity: JSON.parse(JSON.stringify(defaultEntity)),
      filters: JSON.parse(JSON.stringify(defaultEntity)),
      exhibitors: [],

    };
  },
  methods: {
    handleSearch() {
      this.filters = JSON.parse(JSON.stringify(this.filters));
    },
    handleReset() {
      this.filters = JSON.parse(JSON.stringify(defaultEntity));
    },
    async getExhibitors() {
      const result = await ExhibitorService.getEntities();
      this.exhibitors = result;
    },
  },
  mounted() {
    this.handleReset();
    this.getExhibitors();

  },
};
</script>

<style lang="less" scoped>
</style>